<template>
  <div>
    <button @click="startRecording" :disabled="isRecording">Start Recording</button>
    <button @click="stopRecording" :disabled="!isRecording">Stop Recording</button>
    <button @click="playRecording" :disabled="!audioUrl">Play Recording</button>
    <button @click="pauseRecording" :disabled="!isPlaying">Pause</button>
    <button @click="rewindAudio" :disabled="!audioUrl">Rewind 5s</button>
    <button @click="forwardAudio" :disabled="!audioUrl">Forward 5s</button>
    <p v-if="transcript">Transcript: {{ transcript }}</p>
    <textarea v-model="transcript" rows="10" cols="50"></textarea>
    <audio ref="audio" :src="audioUrl"></audio>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isRecording: false,
      isPlaying: false,
      mediaRecorder: null,
      audioChunks: [],
      audioUrl: null,
      audio: null,
      recognition: null,
      transcript: ''
    };
  },
  methods: {
    async startRecording() {
      // Clear previous recording data
      this.audioChunks = [];
      this.audioUrl = null;
      this.transcript = '';
      this.isPlaying = false;

      // Start new recording
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = new MediaRecorder(stream);
      this.mediaRecorder.start();
      this.isRecording = true;
      this.mediaRecorder.ondataavailable = (event) => {
        this.audioChunks.push(event.data);
      };
      this.mediaRecorder.onstop = () => {
        const blob = new Blob(this.audioChunks, { type: 'audio/wav' });
        this.audioUrl = URL.createObjectURL(blob);
        this.convertAudioToText(blob);
      };
    },
    stopRecording() {
      this.mediaRecorder.stop();
      this.isRecording = false;
    },
    playRecording() {
      this.audio = this.$refs.audio;
      this.audio.play();
      this.isPlaying = true;
      this.audio.onended = () => {
        this.isPlaying = false;
      };
    },
    pauseRecording() {
      this.audio.pause();
      this.isPlaying = false;
    },
    rewindAudio() {
      this.audio.currentTime -= 5;
    },
    forwardAudio() {
      this.audio.currentTime += 5;
    },
    convertAudioToText(blob) {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const audioData = reader.result;
        const recognizer = new window.webkitSpeechRecognition();
        recognizer.lang = 'en-US';
        recognizer.continuous = true;
        recognizer.interimResults = false;

        recognizer.onresult = (event) => {
          const transcriptResult = Array.from(event.results)
            .map(result => result[0].transcript)
            .join('');
          this.transcript = transcriptResult;
        };

        recognizer.onerror = (event) => {
          console.error('Speech recognition error', event);
        };

        recognizer.onend = () => {
          console.log('Speech recognition service disconnected');
        };

        recognizer.start();

        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const source = audioContext.createBufferSource();
        fetch(audioData)
          .then(response => response.arrayBuffer())
          .then(buffer => audioContext.decodeAudioData(buffer))
          .then(decodedData => {
            source.buffer = decodedData;
            source.connect(audioContext.destination);
            source.start(0);
          });
      };
    }
  }
};
</script>

<style scoped>
button {
  margin: 5px;
}
textarea {
  width: 100%;
  margin-top: 10px;
}
</style>
