<template>
    <div id="app">
        <VideoRecorder uploadUrl="YOUR_UPLOAD_URL_HERE" />
    </div>
</template>

<script>
import VideoRecorder from './components/VideoRecorder.vue';

export default {
    name: 'App',
    components: {
        VideoRecorder
    }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
